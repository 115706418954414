<template>
    <div class="mb-2 flex justify-center" v-if="nr === 1">
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-yellow-400 text-white mr-1.5 contrast:bg-sky-500">S</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">O</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">N</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">N</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">E</div>
    </div>
    <div class="mb-2 flex justify-center" v-if="nr === 2">
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">K</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">R</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">A</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-green-700 text-white mr-1.5 contrast:bg-orange-500">S</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">S</div>
    </div>
    <div class="mb-2 flex justify-center" v-if="nr === 3">
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-yellow-400 text-white mr-1.5 contrast:bg-sky-500">S</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">U</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">P</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-green-700 text-white mr-1.5 contrast:bg-orange-500">E</div>
        <div class="rounded-sm text-3xl w-12 py-1.5 text-center bg-stone-400 text-white mr-1.5">R</div>
    </div>
</template>
<script>
export default {
    name: "Example",
    props: {
        nr: {
            type: Number,
            required: true,
            default: function () {
                return 1;
            }
        }
    }
}
</script>
