<template>
    <div class="@container container max-w-4xl mx-auto">
        <div id="game">
            <!-- Game -->
            <div class="px-4 pt-4">
                <div id="game-grid" class="mx-auto aspect-game @sm:max-w-sm">
                    <div v-for="row in grid.length" :class="{'animate-shake':grid[row-1].animations.shake}" class="row flex flex-row gap-2 @sm:gap-3 ls-md:gap-1 h-1/6 relative">
                        <div v-for="col in grid[row-1].column.length"
                             v-on:click="clickCell(row-1,col-1)"
                             v-bind:class="[{'animate-bounce':grid[row-1].column[col-1].animations.bounce,'animate-flip':grid[row-1].column[col-1].animations.flip,'animate-jump':grid[row-1].column[col-1].animations.jump,'cell-focus':grid[row-1].column[col-1].focus},grid[row-1].column[col-1].class]"
                             class="cell transition-all ease-linear inline-flex select-none flex-1 items-center uppercase justify-center rounded-sm mb-2 @sm:mb-3 ls-md:mb-1 text-4xl ls-md:text-3xl outline-none focus:outline-none">{{ grid[row-1].column[col-1].letter }}</div>
                    </div>
                </div>
            </div>
            <!-- /Game -->
            <!-- Keyboard -->
            <div id="game-keyboard" class="keyboard mx-auto h-44 @md:h-48 w-full @md:w-3/4 p-1 @md:py-3 ls-md:mt-1">
                <template v-if="(keyboard.show === true && fetchData === true) || showModal">
                    <div class="keyboard-row h-1/3 text-xl flex justify-center gap-1 @sm:gap-2">
                        <div v-on:click="simKeyboardEnter(obj['key'],$event)" :class="keyboard.keys[obj['key']]" class="flex flex-1 mb-2 transition-colors ease-linear delay-150 key select-none cursor-pointer rounded @sm:rounded-lg items-center justify-center outline-none focus:outline-none" v-for="obj in keyboard.rows[0]">{{ obj['letter'] }}</div>
                    </div>
                    <div class="keyboard-row h-1/3 text-xl flex justify-center gap-1 @sm:gap-2">
                        <div v-on:click="simKeyboardEnter(obj['key'],$event)" :class="keyboard.keys[obj['key']]" class="flex flex-1 mb-2 transition-colors ease-linear delay-150 key select-none cursor-pointer rounded @sm:rounded-lg items-center justify-center outline-none focus:outline-none" v-for="obj in keyboard.rows[1]">{{ obj['letter'] }}</div>
                        <div v-on:click="simKeyboardEnter(8,$event)" class="flex flex-1 mb-2 key select-none cursor-pointer rounded @sm:rounded-lg items-center justify-center key-default outline-none focus:outline-none" id="key-8">
                            <icon icon="arrow-left" classes="h-6 w-6"></icon>
                        </div>
                    </div>
                    <div class="keyboard-row h-1/3 text-xl flex justify-center gap-1 @sm:gap-2">
                        <div class="flex-1 mb-2"></div>
                        <div v-on:click="simKeyboardEnter(obj['key'],$event)" :class="keyboard.keys[obj['key']]" class="flex flex-1 mb-2 transition-colors ease-linear delay-150 key select-none cursor-pointer rounded @sm:rounded-lg items-center justify-center outline-none focus:outline-none" v-for="obj in keyboard.rows[2]">{{ obj['letter'] }}</div>
                        <div v-on:click="simKeyboardEnter(13,$event)" class="flex mb-2 flex-2 px-0.5 @sm:px-1 key select-none cursor-pointer rounded @sm:rounded-lg items-center justify-center key-default outline-none focus:outline-none" id="key-13">Enter</div>
                    </div>
                </template>
                <div class="px-3" v-else>
                    <Spinner v-if="!game.end" classes="absolute left-1/2 top-30 -ml-4 w-8 h-8 contrast:fill-orange-500"></Spinner>
                    <Actions v-else :game="game" @new="newGame" :overlay="false"></Actions>
                </div>
            </div>
        </div>
    </div>
    <!-- Stats -->
    <div v-if="showModal" aria-modal="true" class="overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-40 justify-center items-center @md:inset-0 h-modal @sm:h-full flex transition-all duration-300" id="stats">
        <router-view :game="game" :index="index" @new="newGame"></router-view>
    </div>
    <div v-if="showModal" :class="integration === 'dns' ? 'bg-gray-900 bg-opacity-50' : 'bg-white/30 backdrop-blur-sm'" class="fixed inset-0 z-30 transition-all"></div>
    <!-- /Stats -->
</template>

<script>
import { useHead } from "@unhead/vue"
import { reactive } from "vue"
import { useRoute } from "vue-router"
import Actions from "./Actions"
import Icon from "../vendor/publisher/components/Icon"
import Spinner from "../vendor/publisher/components/Spinner.vue";

export default {
    name: "Puzzle",
    components: {
        Spinner,
        Icon,
        Actions
    },
    props: ["date","index"],
    setup() {
        const route = useRoute(), meta = reactive({
            title: route.meta["title"]
        });

        useHead({
            title: () => meta.title
        });

        return {
            meta
        }
    },
    data: function() {
        return {
            game: {
                archive: !!(this.$route["query"]?.date),
                date: this.date,
                end: false,
                hard: false,
                nr: 0,
                row: 0,
                score: [...Array(this.$config.setup.game.rows)].map(() =>[]),
                solution: [],
                success: false,
            },
            html: {
                container: null,
                grid: null,
                keyboard: null,
                result: null,
                title: ""
            },
            integration: this.$integration(),
            userCtrl: false,
            showModal: this.$route["meta"].showModal ?? false,
            fetchData: false,
            grid: [],
            focus: {
                row: 0,
                col: 0
            },
            keyboard: {
                rows: [
                    [81, 87, 69, 82, 84, 90, 85, 73, 79, 80],
                       [65, 83, 68, 70, 71, 72, 74, 75, 76],
                            [89, 88, 67, 86, 66, 78, 77]
                ],
                keys: [],
                show: this.$store.getters.getRemainingGames > 0
            }
        }
    },
    watch: {
        $route ({meta}) {
            this.showModal = meta.showModal;
        },
        hasConnection: function(connection) {
            if(!connection && !this.hasLocalWordsDB) {
                this.goOffline();
            }
        },
        hardMode: function(status) {
            if(this.game.end === false) {
                this.game.hard = status;
                if(this.focus.row > 0) {
                    this.$store.commit("updateGame",{
                        date: this.game.date,
                        solution: this.game.solution,
                        key: "hard",
                        val: Boolean(status)
                    });
                    this.$store.dispatch("writeData");
                }
                this.setTitle(status);
                this.$store.commit("updateCurrentGame",{
                    key: "hard",
                    val: Boolean(status)
                });
            }
        }
    },
    computed: {
        hasLocalWordsDB: function () {
            return this.$store.getters.hasLocalWordsDB
        },
        hardMode: function() {
            return this.$store.getters.isHardMode;
        },
        puzzles: function() {
            return this.$store.getters.getAllPuzzlesByDate(this.date);
        },
        today: function() {
            return this.$store.getters.today;
        }
    },
    created() {
        /**
         * Die Anzahl der heutigen gespielten Spiele entspricht nicht der Anzahl an Spielen-verbleibende Spiele
         * dispatch("setUser")
         */
        if(this.$store.getters.getCountGamesByDate(this.today) !== (this.$config.setup.game.words-this.$store.getters.getRemainingGames)) {
            this.$store.dispatch("setUser");
        }
        this.initGrid();
        this.initKeyboard();
    },
    async mounted() {
        /**
         * 1: Set playground => GameSize
         * 2: Get puzzles
         * 3: Set game
         * 4: Init game
         */
        await this.$nextTick(function() {
            this.setPlayground();
        });

        new Promise((resolve, reject) => {
            this.$store.dispatch("getGames")
                .then(() => {
                    resolve(this.fetchData = true)
                })
                .catch(error => reject(error));
        })
        .then(async () => {
            await this.setGame();
            this.initGame();
        })
        .catch(error => {
            if(!this.online) { // Keine Spiele vorhanden und Nutzer ist offline
                this.goOffline();
            }
            else {
                this.$store.dispatch("showToast",{
                    msg: this.$config.debug ? error : "Ein Fehler ist aufgetreten.",
                    type: "warn",
                    timeout: 5000
                });
            }
        })
        .finally(() => {
            this.$Progress.finish();
            this.setTitle(this.game.hard);
        });
    },
    methods: {
        /**
         * Setzt den Browser-Titel
         * @param hard
         */
        setTitle(hard = false) {
            if(this.$store.getters['auth/isAuth'] && this.game.archive) {
                this.meta.title = this.$config.name + " #" + this.game.nr + (hard ? "*" : "");
            }
            else if(this.game.archive) {
                this.meta.title = "Archiv #" + this.game.nr + (hard ? "*" : "");
            }
        },
        setPlayground() {
            this.html.keyboard = document.getElementById("game-keyboard");
            this.html.grid = document.getElementById("game-grid");
            this.setGameSize();
        },
        setGameSize() {
            const vh = document.documentElement.clientHeight,
                gameSize = vh-80,
                gridSize = gameSize-this.html.keyboard.offsetHeight;

            if(document.body.style.aspectRatio === undefined) {
                this.setRatio();
            }
            else if(gridSize > 250) {
                this.html.grid.style.maxHeight = gridSize + "px";
            }

        },
        setRatio() {
            const vh = window.innerHeight, gameSize = vh-this.html.keyboard.offsetHeight-80,
                ratio = 3/3.658, // Aspect Ratio
                px = 16*2, maxWidth = 384;

            let height = gameSize, width = ratio*gameSize; // Breite errechnet mit der Ratio anhand der verfügbaren Höhe

            if(width > (window.innerWidth-px)) { // Breite ist größer als Display
                width = window.innerWidth-px;
                height = width/ratio;
            }
            else if(width > maxWidth) {
                width = maxWidth;
                height = width/ratio;
            }

            this.html.grid.style.height = height.toFixed(0) + "px";
            this.html.grid.style.width = width.toFixed(0) + "px";
        },
        async setGame() {
            const index = this.$config.setup.game.words - (this.$store.getters.getRemainingGames === 0 ? 1 : this.$store.getters.getRemainingGames);

            this.game = {...this.game,...this.puzzles[this.game.archive ? this.index : index]};

            const solution = this.game.word.toString();
            this.game.solution = solution;

            this.game = {
                ...this.game,...this.$store.getters.getGameById(this.game.date,solution) || {
                    nr: this.game.nr,
                    row: 0,
                    success: false,
                    end: false,
                    score: [...Array(this.$config.setup.game.rows)].map(() => []),
                    hard: this.hardMode,
                }
            };

            if (this.game.hard !== this.hardMode && this.game.end !== true) {
                this.$store.dispatch("setHardMode", this.game.hard);
            }
            this.$store.commit("setCurrentGame", this.game);

            this.keyboard.show = this.game.end === false;
        },
        initGame() {
            this.game.score.every((row,y) => {
                row.forEach((cell,x) => {
                    if((this.game.row > y || this.game.end === true) && cell !== null) {
                        this.grid[y].column[x].letter = String.fromCharCode(cell);
                    }
                });
                if((this.game.row > y || this.game.end === true) && this.$config.setup.game.cols === row.length) {
                    this.successRow(y,true);
                    return true;
                }
                /**
                 * Es wurden noch nicht alle Buchstaben in der Reihe (row) eingegeben.
                 * Das Spiel ist noch nicht zu Ende.
                 * Daher setze den Fokus auf die erste Zelle und aktiviere die Tastatur.
                 */
                else if(this.game.end === false) {
                    this.focusCell(y);
                    this.enableKeyboard();
                }

                return false;
            });
        },
        /**
         * Init, disable & reset keyboard eventListener
         */
        enableKeyboard() {
            window.addEventListener("keydown",this.enterKeyboard);
        },
        disableKeyboard() {
            window.removeEventListener("keydown",this.enterKeyboard);
        },
        resetKeyboard() {
            this.keyboard.keys.forEach((classname,key) => {
                this.keyboard.keys[key] = "key-default";
            });
            this.keyboard.show = true;
        },
        async newGame() {
            if(this.$store.getters.getRemainingGames > 0) {
                /**
                 * 1: Set Game
                 * 4: Init Grid > Reset
                 * 5: Update Grid
                 * 6: Reset Input
                 */
                this.$store.commit("setToday");
                this.resetKeyboard();
                this.initGrid();
                await this.setGame();
                this.focus.row = 0;
                this.focus.col = 0;
                this.focusCell();
                this.enableKeyboard();
                await router.push({
                    name: "Home"
                });
                this.event("NewGame",{
                    game_nr: this.game.nr,
                    remaining_games: this.$store.getters.getRemainingGames
                });
            }
            else {
                this.game.end = true;
                this.$store.dispatch("showToast",{
                    msg: "Das war's für heute.",
                    type: "warn"
                });
                router.push({
                    name: "Home"
                });
            }
        },
        /**
         *
         * @param row
         * @param success
         */
        setStats(row,success = false) {

            const stats = this.$store.getters.getStats;

            if(success === true) {
                stats.trys[row]++;
                stats.wins++;
                stats.streak++;
            }
            else {
                stats.streak = 0;
            }

            stats.games++;

            if(!this.game.archive) {
                this.$store.commit("setRemainingGames",-1);

                if(this.$store.getters.getRemainingGames === 0) {
                    this.event("AllGamesCompleted",{
                        date: this.today,
                    });
                }
            }

            if(stats.maxStreak < stats.streak) {
                stats.maxStreak = stats.streak;
            }

            stats.winPercentage = stats.games > 0 ? ((100 * stats.wins) / stats.games).toFixed(0) : 0;

            this.$store.commit("setStats",stats);
            this.$store.dispatch("writeData",true);

        },
        async updateGame() {
            await this.$store.commit("setCurrentGame",this.game);
            await this.$store.commit("setGame",{
                date: this.game.date,
                solution: this.game.solution,
                nr: this.game.nr,
                row: this.game.row,
                success: this.game.success,
                end: this.game.end,
                score: this.game.score,
                hard: this.hardMode
            });
            this.$store.dispatch("writeData");
        },
        /**
         * Triggered, if user hits "Enter"
         * @param row
         * @returns {Promise<boolean>}
         */
        async verify(row) {
            let filled = 0, hardMode = false;

            this.grid[row].column.forEach((col,i) => {
                if(col.letter === "") {
                    this.bounce(row,i);
                }
                else if(this.game.score[row][i] !== null) {
                    filled++;
                }
            });

            if(filled < this.$config.setup.game.cols) {
                return false;
            }
            else if((hardMode = this.checkHardMode(row)) !== false) {
                this.bounce(hardMode.row,hardMode.col);
                this.$store.dispatch("showToast",{
                    msg: hardMode.msg,
                    type: "warn"
                });
                return false;
            }

            this.disableKeyboard();
            let word = this.game.score[row].join("");

            if(word === this.game.solution) {
                this.successRow(row);
                return false;
            }
            else if(await this.isWord(word) === true) {
                this.successRow(row);
            }
            else {
                this.shake(row);
                this.$store.dispatch("showToast",{
                    msg: this.$config.content.messages.notExists,
                    type: "warn"
                });
                this.enableKeyboard();
            }
        },
        /**
         *
         * @param word
         * @returns {*|(function(*, *=): (*|boolean))}
         */
        isWord(word) {
            // if(this.$store.getters.hasLocalWordsDB) {
                return this.$store.getters.isWord(word);
            /*}
            else {
                let is_word = false;

                await this.$axios.post('/api/dict',{
                    word: encodeURIComponent(word),
                    length: this.$config.setup.game.cols
                }).then((response) => {
                    is_word = response.data.is_word === true;
                }).catch((error) => {
                    this.offlineToast(error);
                });

                return is_word;
            }*/
        },
        /**
         * Only triggered in hard mode
         * @returns {boolean|{col: number, row: number}}
         */
        checkHardMode(row) {
            /**
             * Hard mode rules:
             * If a letter was marked yellow, it must be used in the next attempt.
             * If a letter is marked green, it must be used in exactly the same place in the next attempt.
             */
            if(!this.$store.getters.isHardMode || this.game.score[1].length <= 0) {
                return false;
            }

            let error = false;

            this.grid[row-1].column.every((col,i) => {
                let matches = this.grid[row].column.filter(e => e.letter === col.letter).length, count = this.grid[row-1].column.filter(e => e.letter === col.letter && e.class !== "cell-fail").length;
                if(col.class !== "cell-fail") {
                    if(matches === 0 || count > matches) {
                        // Der Buchstabe ist in der Reihe zuvor genutzt worden, aber nun nicht mehr;
                        // oder er ist weniger vorhanden als zuvor
                        error = {
                            col: i,
                            row: row-1,
                            msg: "Der Buchstabe <strong>" + col.letter + "</strong> muss" + (matches > 0 ? (" " + count + " Mal") : "") + " enthalten sein."
                        };
                        return false;
                    }
                    else if(col.class === "cell-success" && this.grid[row].column[i].letter !== col.letter) {
                        // Der Buchstabe ist in einer Reihe zuvor in einer anderen Spalte schon als "success" gekennzeichnet
                        error = {
                            col: i,
                            row: row-1,
                            msg: "Der Buchstabe <strong>" + col.letter + "</strong> war doch schon richtig gesetzt."
                        };
                        return false;
                    }
                    /*else if(col.class === "cell-near" && this.grid[row].column[i].letter === col.letter) {
                        // Der Buchstabe, der mit "near" gekennzeichnet wurde, kommt an der selben Stelle erneut vor
                        // TODO: Check, if to hard
                        error = {
                            col: i,
                            row: row-1,
                            msg: "Der Buchstabe <strong>"+ col.letter +"</strong> darf nicht mehr an dieser Stelle stehen."
                        };
                        return false;
                    }*/
                }

                 return true;
            });

            return error;
        },
        /**
         * If function verify returns true
         * @param row
         * @param fastForward
         * @returns {boolean}
         */
        successRow(row,fastForward = false) {
            const fill = this.game.score[row+1] || [],
                solution = this.decrypt(this.game.solution),
                next = this.grid[row+1] !== undefined ? this.grid[row+1].column : [],
                result = this.getResult(row,solution,this.game.score);

            let correct = 0;

            this.focusCell(row,4,false); // Set only this.row & this.col

            result.forEach((obj,col) => {

                if(obj.status === "success") {
                    correct++;
                }

                setTimeout(async () => {
                    this.flip(row, col);
                    this.grid[row].column[col].class = obj.class;
                    if (obj.keyboard !== false) {
                        this.keyboard.keys[obj.key] = obj.keyboard;
                    }

                    /**
                     * Nur speichern & Fokus in die nächste Reihe,
                     * wenn die nächste Reihe noch nicht ausgefüllt ist
                     * und der letzte Buchstabe der Reihe erreicht wurde
                     */
                    if (fill.length < 1 && (col + 1) === result.length) {

                        this.game.end = next.length < 1 || correct >= this.$config.setup.game.cols;
                        this.game.success = correct >= this.$config.setup.game.cols;

                        if (fastForward === false) {
                            if (this.game.end === true) {
                                await this.updateGame();
                                this.setStats(row, correct >= this.$config.setup.game.cols);
                                const word = this.arrayCharKeyToString(solution);

                                if (correct >= this.$config.setup.game.cols) { // Success
                                    this.$store.dispatch("showToast", {
                                        msg: this.$config.content.messages.game["success"][row][Math.floor(Math.random() * this.$config.content.messages.game["success"][row].length)],
                                        type: "success",
                                        timeout: 2000,
                                        callback: () => {
                                            this.openStats();
                                        }
                                    });
                                    this.event("GameSucceeded", {
                                        game_nr: this.game.nr,
                                        game_success_row: row + 1
                                    });

                                    solution.forEach((letter, index) => {
                                        setTimeout(() => {
                                            this.jump(row, index);
                                        }, index * 160);
                                    });
                                } else { // Fail
                                    this.$store.dispatch("showToast", {
                                        msg: this.$config.content.messages["warn"] + "<br />Das gesuchte Wort lautet: <strong>" + word + "</strong>",
                                        type: "warn",
                                        timeout: 5000,
                                        callback: () => {
                                            this.openStats();
                                        }
                                    });
                                    this.event("GameFailed", {
                                        game_nr: this.game.nr,
                                        game_success_row: row + 1
                                    });
                                }
                                this.event("GameFinished", {
                                    game_nr: this.game.nr,
                                    game_success_row: row + 1
                                });
                            } else {
                                this.game.row = (row + 1);
                                await this.updateGame();
                                this.focusCell(this.game.row);
                                this.enableKeyboard();
                                this.userCtrl = false;
                                this.event("RowCompleted", {
                                    game_nr: this.game.nr,
                                    game_current_row: this.game.row
                                });
                            }
                        }
                    }
                }, fastForward === true ? row*100 : col*400);
            });
        },
        openStats(init = false) {

            let params = {};

            this.keyboard.show = false;

            if(this.game.archive) {
                params.nr = this.game.nr;
            }

            if(init !== false) {
                params.init = init;
            }

            router.push({
                name: "Stats",
                params: params
            });
        },
        /**
         *
         * @param row
         * @param col
         * @param focus
         */
        focusCell(row = 0,col = 0,focus = true) {
            this.grid[this.focus.row].column[this.focus.col].focus = false;
            this.focus.row = row;
            this.focus.col = col;
            this.$store.commit("setCurrentFocus",row,col);

            if(focus === true && this.grid[row] !== undefined && this.grid[row].column[col] !== undefined) {
                this.grid[row].column[col].focus = true;
            }
        },
        /**
         * UX-Helper for user selected fields
         * @param row
         * @param col
         */
        clickCell(row,col) {
            if(this.focus.row === row && this.game.end === false) {
                this.userCtrl = true;
                this.focusCell(row,col);
            }
        },
        /**
         *
         * @param e
         */
        enterKeyboard(e) {
            const charCode = e.which ? e.which : e.keyCode,
                row = this.focus.row,
                col = this.focus.col,
                elm = this.grid[row].column[col],
                nextCell = this.grid[row].column[col+1] || null,
                prevCell = this.grid[row].column[col-1] || null

            if(charCode >= 65 && charCode <= 90) { // a -z
                elm.letter = String.fromCharCode(charCode);
                elm.class = "cell-insert";
                this.game.score[row][col] = charCode;

                // Soll der Nutzer weiterspringen, wenn im nächsten Feld kein Inhalt ist?
                if(nextCell && (this.userCtrl === false || nextCell.letter === "")) {
                    this.focusCell(row,col+1);
                }
                else if(nextCell) {
                    this.grid[row].column.every((column,i) => {
                        if(i > col && column.letter === "") {
                            this.focusCell(row,i);
                            return false;
                        }
                        return true;
                    });
                }
            }
            else if (charCode === 13) { // Enter
                this.verify(row);
            }
            /**
             * 48: 0
             * 96: numpad 0
             * 46: delete
             * 8: backspace
             */
            else if(charCode === 48 || charCode === 96 || charCode === 46 || charCode === 8) {
                const val = elm.letter;
                elm.letter = "";
                elm.class = "cell-default";
                this.game.score[row][col] = null;

                // Es ist kein Element vorher vorhanden.
                // Ich bin beim ersten Buchstaben
                if(!prevCell) {
                    // nothing
                }
                // Es ist kein Element danach vorhanden.
                // Ich bin beim letzten Buchstaben
                else if(!nextCell) {
                    // Es war nichts eingetragen
                    // Dann springe ein Feld vor und lösche daraus den Inhalt
                    if(val === "") {
                        this.grid[row].column[col-1].class = "cell-default";
                        this.grid[row].column[col-1].letter = "";
                        this.focusCell(row,col-1);
                        this.game.score[row][col-1] = null;
                    }
                    else {
                        // nothing
                    }
                }
                // Es ist sowohl vor und nach ein Element vorhanden
                // Ich bin mittig
                else {
                    // Der Nutzer hat keine Pfeile genutzt,
                    // dann springe ein Feld vor und lösche daraus den Inhalt.
                    if(this.userCtrl === false) {
                        this.grid[row].column[col-1].class = "cell-default";
                        this.grid[row].column[col-1].letter = "";
                        this.game.score[row][col-1] = null;
                        this.focusCell(row,col-1);
                    }
                    // Der Nutzer kontrolliert das Feld;
                    // und das Feld ist leer.
                    // Dann springe ein Feld vor und lösche daraus den Inhalt und deaktiviere die Nutzersteuerung.
                    else if(val === "") {
                        this.grid[row].column[col-1].class = "cell-default";
                        this.grid[row].column[col-1].letter = "";
                        this.userCtrl = false;
                        this.game.score[row][col-1] = null;
                        this.focusCell(row,col-1);
                    }
                    // Der Nutzer kontrolliert das Feld.
                    // Das Feld ist ausgefüllt.
                    else {
                        // Silence is golden
                    }
                }
            }
            /**
             * 37: left
             * 39: right
             * 9: tab
             * 9 + shift: tab back
             */
            else if(charCode === 37) {
            // else if(charCode === 37 || (e.shiftKey && charCode === 9)) { // Left or shift+tab
                if(prevCell) {
                    if(this.grid[row].column[col].letter === "") {
                        this.grid[row].column[col].class = "cell-default";
                    }
                    else {
                        this.grid[row].column[col].class = "cell-insert";
                    }
                    this.focusCell(row,col-1);
                }
                this.userCtrl = true;
            }
            else if(charCode === 39) {
            // else if(charCode === 39 || (!e.shiftKey && charCode === 9)) { // Right or tab
                if(nextCell) {
                    if(this.grid[row].column[col].letter === "") {
                        this.grid[row].column[col].class = "cell-default";
                    }
                    else {
                        this.grid[row].column[col].class = "cell-insert";
                    }
                    this.focusCell(row,col+1);
                }
                this.userCtrl = true;
            }

            this.preventKeyboard(e,charCode,[13,48,96,46,8,37,39]);

        },
        /**
         *
         * @param e
         * @param charCode
         * @param keys
         */
        preventKeyboard(e,charCode,keys = []) {
            if((charCode >= 65 && charCode <= 90) || keys.includes(charCode)) {
                e.preventDefault();
            }
        },
        /**
         *
         * @param code
         * @param e
         */
        simKeyboardEnter(code,e) {
            window.dispatchEvent(new KeyboardEvent("keydown", {
                "keyCode": code
            }));

            if(e) {
                e.preventDefault();
            }
        },
        initGrid() {
            /**
             * grid: {
             *  row:[{
             *      animation: {
             *          shake: {}
             *      },
             *      col:[{
             *          letter: String,
             *          animations: {
             *              bounce: {},
             *              flip: {}
             *          }
             *      }]
             *  }]
             * }
             */
            for(let row = 0;row<this.$config.setup.game.rows;row++) {
                this.grid[row] = {
                    animations: {
                        shake: false
                    },
                    column: []
                };
                for(let column = 0;column<this.$config.setup.game.cols;column++) {
                    this.grid[row].column[column] = {
                        class: "cell-default",
                        focus: false,
                        letter: "",
                        animations: {
                            bounce: false,
                            flip: false,
                            jump: false
                        }
                    };
                }
            }
        },
        initKeyboard() {
            this.keyboard.rows.forEach((row,r)=>{
                row.forEach((key,k)=>{
                    this.keyboard.rows[r][k] = {
                        key: key,
                        letter: String.fromCharCode(key).toUpperCase()
                    };
                    this.keyboard.keys[key] = "key-default";
                });
            });
        },
        /**
         * Animations
         */
        shake(row) {
            this.grid[row].animations.shake = true;
            setTimeout(() => {
                this.grid[row].animations.shake = false
            }, 900);
        },
        bounce(row,col) {
            this.grid[row].column[col].animations.bounce = true;
            setTimeout(() => {
                this.grid[row].column[col].animations.bounce = false
            }, 400);
        },
        jump(row,col) {
            this.grid[row].column[col].animations.jump = true;
            setTimeout(() => {
                this.grid[row].column[col].animations.jump = false
            }, 900);
        },
        flip(row,col) {
            if(this.$route["name"] !== "Stats") {
                this.grid[row].column[col].animations.flip = true;
                setTimeout(() => {
                    this.grid[row].column[col].animations.flip = false
                }, 500);
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener("keydown",this.enterKeyboard);
    }
}
</script>
