<template>
    <div role="dialog" aria-modal="true" v-if="showModal" class="overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full flex transition-all duration-300" id="init">
        <div class="relative px-4 w-full max-w-lg h-full md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal body -->
                <div class="px-5 pt-5 pb-3 sm:pb-5 rounded-t-lg relative">
                    <button aria-label="Fenster schließen" type="button" class="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-3.5 right-3.5 dark:hover:bg-gray-600 dark:hover:text-white" v-on:click="closeModal">
                        <icon icon="x-mark" classes="w-5 h-5"></icon>
                    </button>
                    <h1 class="mb-3 text-center dark:text-gray-300 font-bold text-xl">So wird gespielt</h1>
                    <ol class="text-sm sm:text-base list-decimal list-outside ml-5 mb-2 sm:mb-5 dark:text-gray-300">
                        <li>Ein <strong>beliebiges, existierendes Wort</strong> mit fünf Buchstaben eingeben.</li>
                        <li><strong>"Enter"</strong> auf der Tastatur drücken.</li>
                        <li>Die <strong>Farben</strong> zeigen, wie nahe {{ $addressingUser('du','Sie') }} dem Lösungswort {{ $addressingUser('bist','sind') }}:</li>
                    </ol>
                    <Example :nr="1"></Example>
                    <p class="text-sm sm:text-base mb-2 text-justify dark:text-gray-300">Der Buchstabe <strong>S</strong> kommt im Wort vor, steht aber an der falschen Position.
                        <strong>O</strong>, <strong>N</strong> und <strong>E</strong> kommen <i>nicht</i> im gesuchten Wort vor.</p>
                    <Example :nr="2"></Example>
                    <p class="text-sm sm:text-base text-justify dark:text-gray-300">Der Buchstabe <strong>S</strong> steht jetzt an der richtigen Stelle. {{ $addressingUser('Du hast','Sie haben') }} <strong>6 Versuche</strong>, das Wort zu erraten.</p>
                </div>
                <!-- Modal footer -->
                <div class="px-5 py-3 flex items-center justify-between sm:py-5 bg-gray-200 rounded-b-lg border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:border-t">
                    <ul class="text-sm sm:text-base flex-none list-inside text-gray-800 dark:text-gray-300">
                        <li>
                            <icon icon="cake" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            Jeden Tag {{ config.setup.game.words === 1 ? "ein" : config.setup.game?.words }} neue{{ config.setup.game?.words === 1 ? "s Wort" : " Wörter"}}
                        </li>
                        <li class="my-2 sm:my-3" v-if="config.setup?.features?.archive && config.setup?.game?.max_archive_days">
                            <icon icon="calendar-days" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            {{ config.setup?.game?.max_archive_days }}-Tage-Archiv
                        </li>
                        <li class="my-2 sm:my-3" v-if="config.common['with_authentication']">
                            <icon icon="cloud-arrow-down" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            Spielstand-Speicher
                        </li>
                        <li class="my-2 sm:my-3">
                            <icon icon="check-badge" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            Handverlesene Wörter
                        </li>
                    </ul>
                    <div class="text-center">
                        <button class="btn btn-primary" v-on:click="closeModal">Start</button>
                        <span v-if="android" class="italic block text-sm my-3 text-gray-800">oder</span>
                        <a v-if="android" :href="androidAppStore()" target="_blank">
                            <img src="./img/google-play-badge.png" class="max-w-xs mx-auto h-auto w-24 sm:w-48" width="646" height="250" alt="Google Play Store Logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.showModal" class="bg-gray-900 bg-opacity-50 fixed inset-0 z-40 transition-all"></div>
</template>
<script>
import Example from "./Example"
import { buildURLWithParams } from "../vendor/publisher/connector-request";
import Icon from "../vendor/publisher/components/Icon";

export default {
    data() {
        return {
            showModal: false,
            android: navigator.userAgent.match(/android/i) && this.$config.common["android_app_store_url"],
            config: this.$config
        }
    },
    created() {
        if(!this.$store.getters.dataExists && this.$route["name"] === "Home") {
            this.showModal = true;
            this.$store.commit("setExistingData",true);
            document.getElementById( "app").addEventListener("click",this.clickOverlay,true);
        }
    },
    components: {
        Icon,
        Example
    },
    methods: {
        /**
         *
         * @param e
         */
        clickOverlay(e) {
            const modal = document.querySelector("[aria-modal]:not([id=CybotCookiebotDialog])");
            if(modal && !e.target.closest('#' + modal.getAttribute("id") + ' .shadow')) {
                this.closeModal(e);
            }
        },
        /**
         *
         * @param e
         */
        closeModal(e) {
            e.preventDefault();
            this.showModal = false;
            document.getElementById("app").removeEventListener("click",this.clickOverlay,true);
        },
        /**
         *
         * @returns {*}
         */
        androidAppStore() {
            return buildURLWithParams(this.$config.common["android_app_store_url"],Object.create({'referrer':'utm_source%3Dwrdl.de%26utm_content%3Dinit%26utm_campaign%3Dapp%26utm_medium%3Dweb'}))
        }
    }
}
</script>
