<template>
    <div class="static">
        <ol v-if="fetchData" class="relative border-l border-gray-200 dark:border-gray-700" data-accordion="collapse" data-active-classes="bg-gray-50 dark:bg-gray-700 rounded-b-none active" data-inactive-classes="bg-white dark:bg-gray-800 inactive">
            <li class="mb-10 ml-4" v-for="day in entries">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ day.dateToString }}</time>
                <ol class="w-full sm:w-96 text-sm font-medium text-gray-900 border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-600">
                    <li class="w-full" v-for="(game,index) in day.games">
                        <button :data-accordion-target="'#accordion-collapse-body-' + day.date + '-' + game.nr" :aria-controls="'accordion-collapse-body-' + day.date + '-' + game.nr" v-bind:class="className(index)" class="group w-full px-4 py-2 flex cursor-pointer inactive hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700" v-bind:id="'accordion-collapse-heading-' + day.date + '-' + game.nr">
                            <icon v-if="game.user && game.user.end && !game.user.success" icon="check-circle" classes="h-5 w-5 mr-2 text-green-700 dark:text-green-400 contrast:!text-orange-500"></icon>
                            <icon v-if="game.user && game.user.end && game.user.success" icon="check-badge" type="solid" classes="h-5 w-5 mr-2 text-green-700 dark:text-green-400 contrast:!text-orange-500"></icon>
                            <icon v-if="!game.user.end" icon="exclamation-circle" classes="h-5 w-5 mr-2 text-yellow-600 dark:text-yellow-300 contrast:!text-sky-500"></icon>
                            <span class="text-left grow text-gray-500 dark:text-gray-300">Wort #{{ game.nr + (game.user.hard === true && game.user.score[0].length > 0 ? '*' : '') }}</span>
                            <!-- Solution hide -->
                            <span v-if="game.user.end" class="group-[.active]:hidden text-gray-500 text-xl leading-none relative -bottom-1 dark:text-gray-300">
                                <span class="mr-1" v-for="col in $config.setup.game.cols">*</span>
                            </span>
                            <!-- /Solution hide -->
                            <!-- Solution visible -->
                            <span v-if="game.user.end" class="group-[.inactive]:hidden text-gray-500 mr-2 dark:text-gray-300">
                                {{ arrayCharKeyToString(decrypt(game.user.solution)) }}
                            </span>
                            <!-- /Solution visible -->
                            <!-- Eye closed -->
                            <icon v-if="game.user.end" icon="eye-slash" classes="group-[.active]:hidden w-5 h-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Eye closed -->
                            <!-- Eye open -->
                            <icon v-if="game.user.end" icon="eye" classes="group-[.inactive]:hidden w-5 h-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Eye open -->
                            <!-- Arrow down -->
                            <icon v-if="!game.user.end" icon="chevron-down" classes="group-[.active]:hidden h-5 w-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Arrow down -->
                            <!-- Arrow up -->
                            <icon v-if="!game.user.end" icon="chevron-up" classes="group-[.inactive]:hidden h-5 w-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Arrow up -->
                        </button>
                        <div v-bind:id="'accordion-collapse-body-' + day.date + '-' + game.nr" class="hidden">
                            <div class="p-5 border-gray-200 dark:border-gray-600" v-bind:class="index !== Object.keys(day.games).length-1 ? 'border-b' : 'border-t'">
                                <p class="text-gray-500 dark:text-gray-300" v-if="game.user && game.user.end === false && game.user.score[0].length > 0">
                                    Wort #{{ game.nr }} {{ $addressingUser('hast du','haben Sie') }} angefangen aber nicht zu Ende gespielt. Spiele{{ $addressingUser('','n Sie') }} jetzt weiter.
                                </p>
                                <p class="text-gray-500 dark:text-gray-300" v-else-if="!game.user || game.user.score[0].length === 0">
                                    Wort #{{ game.nr }} {{ $addressingUser('hast du','haben Sie') }} noch nicht gespielt.{{ today !== day.date ? (" " + $addressingUser('Du kannst','Sie können') + ' dies jetzt nachholen.') : '' }}
                                </p>
                                <p class="text-gray-500 dark:text-gray-300" v-if="game.user.end && game.user.success">Wort #{{ game.nr }} {{ $addressingUser('hast du','haben Sie') }} in {{ game.user.row+1 }} von {{ $config.setup.game.rows }} Versuchen gelöst.</p>
                                <p class="text-gray-500 dark:text-gray-300" v-else-if="game.user.end && !game.user.success">Wort #{{ game.nr }} {{ $addressingUser('hast du','haben Sie') }} nicht gelöst.</p>
                                <p v-if="game.user && game.user.score[0].length > 0 && game.user.hard" class="text-gray-500 dark:text-gray-300">* Der <strong>schwierige Modus</strong> {{ game.user.end ? 'war' : 'ist' }} aktiv.</p>
                                <router-link v-if="!game.user.end" :to="routeArchive(index,day.date)" custom v-slot="{ navigate }">
                                    <div class="grid justify-items-end">
                                        <button @click="navigate" class="btn-default btn-medium mt-3 inline-flex items-center justify-self-end">
                                            {{ !game.user || game.user.score[0].length === 0 ? (today !== day.date ? 'Spielen' : 'Zur Startseite') : 'Weiterspielen' }}
                                            <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
                                        </button>
                                    </div>
                                </router-link>
                                <div class="flex justify-between space-x-4 mt-3" v-if="game.user.end">
                                    <div class="whitespace-pre-line tracking-wrdl shrink">
                                        {{ game.user.result }}
                                    </div>
                                    <div class="flex-1">
                                        <button class="btn btn-default btn-medium w-full btn-default inline-flex items-center flex-none" @click="copyToClipboard(buildShareText(game.nr,game.user.row,game.user.success,game.user.score,game.user.hard,game.user.solution))">
                                            <icon icon="document-duplicate" classes="h-4 w-4 mr-1 -ml-1"></icon>
                                            Ergebnis kopieren
                                        </button>
                                        <button class="btn btn-default btn-medium w-full btn-default inline-flex items-center mt-3 flex-none" v-if="$store.getters.canShare" @click="shareResult(game.nr,game.user.row,game.user.success,game.user.score,game.user.hard,game.user.solution)">
                                            <icon icon="share" classes="h-4 w-4 mr-1 -ml-1"></icon>
                                            Ergebnis teilen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </li>
        </ol>
        <Spinner v-else classes="absolute left-1/2 top-30 -ml-4 w-8 h-8 contrast:fill-orange-500"></Spinner>
    </div>
</template>

<script>
import Share from "@mixins/share.mix.js"
import Icon from "../components/Icon"
import { initAccordions } from "flowbite"
import Spinner from "../components/Spinner.vue"

export default {
    name: "Archive",
    components: {
        Spinner,
        Icon
    },
    mixins: [
        Share
    ],
    data() {
        return {
            words: this.$config.setup.game.words,
            fetchData: false,
            countDays: 1,
            entries: [],
            lastGameNr: null,
            accordionItems: [],
        }
    },
    computed: {
        puzzles: function() {
            return this.$store.getters.getAllPuzzlesByDate(this.today);
        },
        today: function() {
            return this.$store.getters.today;
        }
    },
    created() {
        // this.$store.commit("setToday");
        this.countDays += this.$config.setup.game?.max_archive_days;
    },
    mounted() {
        new Promise((resolve, reject) => {
            this.$store.dispatch("getGames")
                .then(() => {
                    resolve(this.fetchData = true)
                })
                .catch(error => reject(error));
        })
            .then(async () => {
                this.lastGameNr = this.puzzles[this.words-1].nr;
                await this.setTimeline();
                initAccordions();
            })
            .finally(() => {
                this.fetchData = true;
            });
    },
    methods: {
        setTimeline() {
            const today = new Date(new Date(this.today).setHours(0,0,0,0));

            for(let i = 0;i < this.countDays;i++) {
                let date = new Date(new Date(this.today).setHours(0,0,0,0));
                date.setDate(today.getDate()-i);
                let dateKey = this.dateKey(date);
                const games = this.getGames(i,dateKey);

                if(games.length > 0) {
                    this.entries[i] = {
                        date: dateKey,
                        day: date.getDay(),
                        dateToString: this.dateToString(i,date),
                        games: games,
                    };
                }
            }
        },
        /**
         *
         * @param index
         * @param date
         * @returns {{query: {}, name: string}}
         */
        routeArchive(index,date) {
            const query = {};

            if(this.today !== date) {
                query["date"] = date;
                query["index"] = index;
            }

            return {
                name: "Home",
                query: query
            }
        },
        /**
         *
         * @param date
         * @returns {string}
         */
        dateKey(date) {
            const dd = String(date.getDate()).padStart(2, '0'),
                mm = String(date.getMonth() + 1).padStart(2, '0'),
                yyyy = date.getFullYear();

            return yyyy + '-' + mm + '-' + dd;
        },
        /**
         *
         * @param index
         * @param date
         * @returns {string}
         */
        dateToString(index,date) {
            let string = "";

            if(index === 0) {
                string+= "Heute";
            }
            else if(index === 1) {
                string+= "Gestern";
            }
            else {
                string+= date.toLocaleString("de-de",{
                    weekday: "long"
                });
            }

            string+= ", ";
            string+= String(date.getDate()).padStart(2,"0");
            string+= ". ";
            string+= date.toLocaleString("de-de",{
                month: "long"
            });

            return string;
        },
        /**
         *
         * @param index
         * @param date
         * @returns {*[]}
         */
        getGames(index,date) {
            let games = [];

            if(index === 0) { // Today
                for(let i = 0;i<this.puzzles.length;i++) {
                    games[i] = {
                        nr: this.puzzles[i].nr,
                        user: this.getUserGame(this.puzzles[i].nr,date),
                        index: index+i
                    }
                }
            }
            else {
                for(let i = 1;i<=this.words;i++) {
                    let nr = this.lastGameNr-this.words-(index*this.words)+i;

                    if(nr > 0) {
                        games[i-1] = {
                            nr: nr,
                            user: this.getUserGame(nr,date),
                            index: (index*this.words)+(i-1)
                        };
                    }
                }
            }

            return games;
        },
        /**
         *
         * @param nr
         * @param date
         * @returns {boolean}
         */
        getUserGame(nr,date) {
            const games = this.$store.getters.getGamesByDate(date);

            let userGame = false;

            if(games && Object.keys(games).length > 0) {
                Object.keys(games).forEach((solution)=>{
                    if(games[solution].nr === nr) {
                        userGame = {
                            row: games[solution].row,
                            hard: games[solution].hard,
                            end: games[solution].end,
                            success: games[solution].success,
                            score: games[solution].score,
                            result: this.buildEmojiSquares(games[solution].row,solution,games[solution].score),
                            solution: solution
                        };
                    }
                });

                return userGame;
            }

            return false;
        },
        /**
         *
         * @param index
         * @returns {string}
         */
        className(index = 0) {
            if(index === 0 && this.words > 1) {
                return 'border-b border-gray-200 dark:border-gray-600 rounded-t-lg';
            }
            else if(index === 0) {
                return 'rounded-lg';
            }
            else if(index < (this.words-1)) {
                return 'border-b border-gray-200 dark:border-gray-600';
            }
            else {
                return 'rounded-b-lg';
            }
        }
    },
    beforeRouteEnter() {
        if(!store.getters.getConfig.setup?.features?.archive || store.getters.getConfig.setup?.features?.archive !== true) {
            router.replace({
                name: "Home"
            });
        }
    }
}
</script>
