import { config } from "../config";

export const user = {
    state: () => ({
        auth: false,
        token: null,
        verified: false,
        synced: true,
        online: true,
        localStorageOrDataExists: false,
        canShare: window.navigator && window.navigator.share !== undefined,
        stats: {
            games: 0,
            wins: 0,
            winPercentage: 0,
            streak: 0,
            maxStreak: 0,
            trys: null,
            remainingGames: 0
        },
        setup: {
            darkMode: false,
            contrast: false,
            hardMode: false
        },
        user: null
    }),
    actions: {
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @returns {Promise<void>}
         */
        setUser: ({commit,dispatch,getters}) => {
            return new Promise(async (resolve) => {
                await dispatch("migrateOldLocalStorage","_wrdl");

                if (typeof getters.getConfig === "function") {
                    config = getters.getConfig;
                }

                /**
                 * Es wird sich ausschließlich aus dem localStorage bedient.
                 * Nur bei der Registrierung, beim Login und beim Abschluss eines Spiels gibt es eine Synchronisierung mit der DB
                 */
                const data = {
                    stats: JSON.parse(localStorage.getItem(config.identifier + "-user-stats")) || {},
                    games: JSON.parse(localStorage.getItem(config.identifier + "-user-games")) || {}
                };

                if (Object.keys(data.stats).length > 0 || Object.keys(data.games).length > 0) {
                    /**
                     * setExistingData === true verhindert,
                     * dass der Init-Layer erneut angezeigt wird.
                     */
                    commit("setExistingData",Object.keys(data.games).length > 0 || (Object.keys(data.stats).length > 0 && data.stats.games > 0));

                    /**
                     * Die Daten werden explizit gesetzt,
                     * um zu verhindern, dass die Daten manipuliert werden.
                     */
                    commit("setStats", {
                        games: data.stats.games,
                        wins: data.stats.wins,
                        winPercentage: data.stats.winPercentage,
                        streak: data.stats.streak,
                        maxStreak: data.stats.maxStreak,
                        trys: data.stats.trys || [...Array(config.setup.game.rows)].map(ignore => 0)
                    });

                    dispatch("setGames", data.games);
                    commit("sortGames");
                }
                /**
                 * No data exist; init localStorage.
                 */
                else {
                    dispatch("writeData");
                }

                /**
                 * Die Einstellungen können jederzeit abgefragt und gespeichert werden
                 */
                commit("setSetup", {
                    key: "darkMode",
                    _class: "dark",
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "darkMode".toLowerCase())) || false
                });
                commit("setSetup", {
                    key: "contrast",
                    _class: "contrast",
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "contrast".toLowerCase())) || false
                });
                commit("setSetup", {
                    key: "hardMode",
                    _class: null,
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "hardMode".toLowerCase())) || false
                });

                commit("auth/setSynced", localStorage.getItem(config.identifier + "-user-synced") !== "false");

                resolve(true);
            });
        },
        /**
         * TODO: Remove after 1 year (2024-07-14) from launch (2023-07-15) this script
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param key
         * @returns {Promise<void>}
         */
        migrateOldLocalStorage: async ({commit,dispatch,getters},key) => {
            const items = [
                "user-synced",
                "user-token",
                "user-games",
                "user-stats",
                "user-data",
                "pref-hardmode",
                "pref-contrast",
                "pref-darkmode"
            ];

            for (const item of items) {
                const value = localStorage.getItem(key + "-" + item);
                if(value) {
                    await dispatch("writeToLocalStorage",{
                        key: item,
                        val: value
                    });
                }
                localStorage.removeItem(key + "-" + item);
            }
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param sync
         * @returns {Promise<void>}
         */
        writeData: async ({commit,dispatch,getters},sync = false) => {
            const stats = JSON.stringify(getters.getStats || {}),
                games = JSON.stringify(getters.getAllGames || {});

            await dispatch("writeToLocalStorage",{
                key: "user-stats",
                val: stats
            });
            await dispatch("writeToLocalStorage",{
                key: "user-games",
                val: games
            });

            if(sync === true) {
                dispatch("auth/sync",{
                    stats: stats,
                    games: games
                });
            }
        },
        /**
         *
         * @param commit
         * @param getters
         * @param key
         * @param val
         * @returns {Promise<void>}
         */
        writeToLocalStorage: async ({commit,getters},{key,val}) => {
            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            await localStorage.setItem(config.identifier + "-" + key,val);
        },
        /**
         *
         * @param commit
         * @param status
         */
        setHardMode: ({commit},status) => {
            commit("setSetup",{
                key: "hardMode",
                _class: null,
                status: status
            });
        }
    },
    mutations: {
        /**
         *
         * @param state
         * @param games
         * @param wins
         * @param winPercentage
         * @param streak
         * @param maxStreak
         * @param trys
         */
        setStats: (state,{
            games,wins,winPercentage,streak,maxStreak,trys
        }) => {
            state.stats.games = parseInt(games) || 0;
            state.stats.wins = parseInt(wins) || 0;
            state.stats.winPercentage = parseInt(winPercentage) || 0;
            state.stats.streak = parseInt(streak) || 0;
            state.stats.maxStreak = parseInt(maxStreak) || 0;
            // state.stats.trys = trys || [...Array(config.setup.game.rows)].map(x => 0);
            state.stats.trys = trys;
        },
        /**
         *
         * @param state
         */
        resetStats: (state) => {
            state.stats = {
                games: 0,
                wins: 0,
                winPercentage: 0,
                streak: 0,
                maxStreak: 0,
                trys: null,
                remainingGames: 0
            };
        },
        /**
         *
         * @param state
         * @param addOrLose
         */
        setRemainingGames: (state,addOrLose) => {
            state.stats.remainingGames = parseInt(state.stats.remainingGames+addOrLose);
        },
        /**
         *
         * @param state
         * @param puzzles
         */
        resetRemainingGames: (state,puzzles) => {
            state.stats.remainingGames = puzzles;
        },
        /**
         *
         * @param state
         * @param status
         */
        setExistingData: (state,status) => {
            state.localStorageOrDataExists = status;
        },
        /**
         *
         * @param state
         * @param key
         * @param _class
         * @param status
         */
        setSetup: (state,{key,_class,status = false}) => {
            state.setup[key] = status;

            if(status && _class) {
                document.querySelector("html").classList.add(_class);
            }
            else if (_class) {
                document.querySelector("html").classList.remove(_class);
            }
        },
    },
    getters: {
        /**
         *
         * @param state
         * @returns {boolean}
         */
        dataExists: state => {
            return state.localStorageOrDataExists;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        getStats: state => {
            return state.stats;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getStat: state => (key) =>  {
            if(state.stats[key] !== undefined) {
                return state.stats[key];
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {any}
         */
        getRemainingGames: state =>  {
            return state.stats.remainingGames > 0 ? state.stats.remainingGames : 0;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isDarkMode: state => {
            return state.setup.darkMode;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isContrast: state => {
            return state.setup.contrast;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isHardMode: state => {
            return state.setup.hardMode;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        canShare: state => {
            return state.canShare;
        },
    }
}
