export default {
    methods: {
        /**
         *
         * @param encrypt
         * @returns {number[]}
         */
        decrypt(encrypt = null) {
            let word = encrypt.match(/.{1,2}/g) || [];
            word = word.map(function(i) {
                return parseInt(i,10);
            });
            return word;
        },
        /**
         *
         * @param arrCharKey
         * @returns {string}
         */
        arrayCharKeyToString(arrCharKey = []) {
            const string = arrCharKey.map(function(code) {
                return String.fromCharCode(code).toUpperCase();
            });

            return string.join("");
        },
        /**
         * TODO: rename function
         * @param row
         * @param solution
         * @param input
         * @returns {*[]}
         */
        getResult(row = 0,solution,input) {
            let word = {}, result = [], maybe = [];

            solution.forEach((i) => {
                word[i] = (word[i] || 0) + 1;
            });

            input[row].forEach((key,i) => {
                result[i] = {
                    key: key,
                    status: "success",
                    class: "cell-success",
                    keyboard: "key-success"
                };

                if(key === solution[i]) {
                    word[key]--;
                }
                else if(!solution.includes(input[row][i])) {
                    result[i].class = "cell-fail";
                    result[i].status = "fail";
                    result[i].keyboard = "key-fail";
                }
                else {
                    maybe[i] = key;
                }
            });

            maybe.forEach((key,i) => {
                if(word[key] > 0) {
                    word[key]--;
                    result[i].class = "cell-near";
                    result[i].status = "near";
                    result[i].keyboard = "key-near";
                }
                else {
                    result[i].class = "cell-fail";
                    result[i].status = "fail";
                    result[i].keyboard = false;
                }
            });

            return result;
        },
        onCountdownEnd() {
            setTimeout(this.$router.go(0),1000);
        },
        tilNextDay() {
            const midnight = new Date(new Date().setHours(24,0,0,0));
            return midnight.getTime() - new Date().getTime();
        }
    }
}
