import { createWebHistory, createRouter } from "vue-router";

import Home from "../pages/Home"
import Stats from "../pages/Stats"

export const routes = [
    {
        name: "Home",
        path: "/",
        component: Home,
        meta: {
            finishProgress: false,
            hardModeChangeAllowed: true,
            // title: "Wordle online auf Deutsch - Drei handverlesene Wörter pro Tag",
            title: "Wordle auf Deutsch online spielen - Jedes Wort handverlesen",
            // description: "Spielen Sie kostenlos das beliebte Wort-Puzzle auf Deutsch und erraten Sie jeden Tag mit jeweils sechs Versuchen drei neue handverlesene Wörter",
            description: "Spielen Sie kostenlos das beliebte Wort-Rätsel Wordle auf Deutsch und erraten Sie jeden Tag mit jeweils sechs Versuchen drei neue handverlesene Wörter",
            robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        },
        children: [
            {
                name: "Stats",
                path: "statistiken",
                component: Stats,
                meta: {
                    showModal: true,
                    title: "Statistiken",
                    description: "",
                    robots: "noindex, follow, noarchive"
                },
                beforeEnter(to,from,next) {
                    to.query.date = from.query.date;
                    to.query.index = from.query.index;
                    next();
                },
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if(to.hash) {
            return {
                left: 0,
                top: 30,
                el: to.hash,
                behavior: "smooth",
            }
        }
        else if(savedPosition) {
            return savedPosition;
        }
        else {
            return {
                behavior: "smooth",
                left: 0,
                top: 0
            }
        }
    }
});

export default router;
