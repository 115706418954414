<template>
    <div class="relative px-4 w-full max-w-lg h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex justify-between items-center py-3 px-5 sm:p-5 rounded-t border-b dark:border-gray-600">
                <h3 class="text-lg inline-flex sm:text-lg font-medium text-gray-900 dark:text-white">
                    <icon icon="chart-bar" classes="h-6 w-6 mr-2 text-gray-600 dark:text-gray-500"></icon>
                    {{ $addressingUser('Deine','Ihre') }} Statistik
                </h3>
                <button @click="closeOverlay" aria-label="Fenster schließen" class="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <icon icon="x-mark" classes="w-5 h-5"></icon>
                </button>
            </div>
            <!-- Modal body -->
            <div class="py-3 px-5 sm:p-5 space-y-5 border-b dark:border-gray-600">
                <div class="flex justify-center gap-4 dark:text-gray-300">
                    <div class="text-center">
                        <div class="flex-none font-bold text-2xl sm:text-3xl">{{ $store.getters.getStat("games") }}</div>
                        <label class="block text-sm sm:text-base break-words">x gespielt</label>
                    </div>
                    <div class="text-center">
                        <div class="flex-none font-bold text-2xl sm:text-3xl">{{ $store.getters.getStat("winPercentage") }}%</div>
                        <label class="block text-sm sm:text-base break-words">Wörter gelöst</label>
                    </div>
                    <div class="text-center">
                        <div class="flex-none font-bold text-2xl sm:text-3xl">{{ $store.getters.getStat("streak") }}</div>
                        <label class="block text-sm sm:text-base break-words">aktuelle Siegesstrecke</label>
                    </div>
                    <div class="text-center">
                        <div class="flex-none font-bold text-2xl sm:text-3xl">{{ $store.getters.getStat("maxStreak") }}</div>
                        <label class="block text-sm sm:text-base break-words">längste Siegesstrecke</label>
                    </div>
                </div>
            </div>
            <!-- Trys -->
            <div class="py-3 px-5 sm:p-5 space-y-2 border-b dark:border-gray-600">
                <div class="flex items-center mb-4">
                    <p class="bg-stone-200 text-stone-600 text-sm font-semibold inline-flex items-center p-1.5 rounded dark:bg-gray-300 dark:text-gray-700">{{ getAvgTrys($store.getters.getStat("trys")) }}</p>
                    <p class="ml-2 font-medium text-gray-900 dark:text-white">Ø Versuche</p>
                </div>
                <dl v-for="trys in $config.setup.game.rows" class="flex items-center">
                    <dt class="text-sm font-medium text-gray-500 dark:text-gray-300 whitespace-nowrap">{{ trys }}. Versuch</dt>
                    <dd class="grow">
                        <div class="bg-gray-200 rounded-lg h-2.5 dark:bg-gray-400 mr-2 ml-2">
                            <div v-bind:class="game.end === true && game.success === true && game.row === (trys-1) ? 'bg-brand-500 dark:bg-brand-400' : $config.layout.colors.fail + ' dark:bg-gray-800'" class="h-2.5 rounded-lg" v-bind:style="'width:' + ($store.getters.getStat('trys').length > 0 && $store.getters.getStat('trys')[trys-1] > 0 ? ((100 * $store.getters.getStat('trys')[trys-1]) / $store.getters.getStat('wins')).toFixed(0) : 0) + '%'"></div>
                        </div>
                    </dd>
                    <dd class="w-6">
                        <span class="text-sm font-medium text-gray-500 dark:text-gray-300">{{ $store.getters.getStat("trys")[trys-1] }}</span>
                    </dd>
                </dl>
            </div>
            <!-- /Trys -->
            <!-- Modal footer -->
            <Actions @new="newGame" @close="closeOverlay" :game="game" :overlay="true"></Actions>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import Actions from "../components/Actions";
import { config } from "../config"
import Icon from "../vendor/publisher/components/Icon";

export default {
    name: "Stats",
    props: ["game","index"],
    components: {
        Icon,
        Actions,
    },
    setup() {
        const route = useRoute();
        useHead({
            title: route.meta.title
        });
    },
    data() {
        return {
            config: config
        }
    },
    mounted() {
        document.body.addEventListener("click",this.clickOverlay,true);
    },
    unmounted() {
        document.body.removeEventListener("click",this.clickOverlay,true);
    },
    methods: {
        newGame() {
            this.$emit("new");
        },
        /**
         *
         * @param e
         */
        closeOverlay(e) {
            e.preventDefault();
            const route = {
                name: "Home"
            };

            if(this.game.archive) {
                route["query"] = {
                    date: this.game.date,
                    index: this.index
                };
            }

            router.push(route);
        },
        /**
         *
         * @param e
         */
        clickOverlay(e) {
            const modal = document.querySelector("[aria-modal]:not([id=CybotCookiebotDialog])");
            if(modal && !e.target.closest('#' + modal.getAttribute("id") + ' .shadow')) {
                this.closeOverlay(e);
            }
        },
        /**
         *
         * @param obj
         * @returns {string}
         */
        getAvgTrys(obj) {
            let trys = [], x = 0, sum = 0;
            for(const [key,value] of Object.entries(obj)) {
                for(let i = 0;i<value;i++) {
                    trys[x] = parseInt(key)+1;
                    sum += trys[x];
                    x++;
                }
            }

            if(trys.length <= 0) {
                return "0.0";
            }

            return (sum/trys.length).toFixed(1);
        }
    }
};
</script>
