<template>
    <div class="flex items-center">
        <auth-icon></auth-icon>
        <help-icon></help-icon>
        <router-link replace :to="{name:'Stats',params:$route.params.nr ? {nr:$route.params.nr} : null}" title="Statistiken" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
            <icon icon="chart-bar" classes="h-6 w-6"></icon>
        </router-link>
        <button aria-label="Menü" @click="openNav" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
            <icon icon="bars-3" classes="h-6 w-6"></icon>
        </button>
    </div>
    <div v-bind:class="[canvas && transition ? 'right-0' : '-right-80',!transition ? 'hidden': null,config.common?.integration !== null && config.common?.integration !== 'dns' ? 'shadow' : null]" id="off-canvas" class="h-full fixed z-50 top-0 overflow-x-hidden transition-all duration-300 bg-white w-80 dark:bg-gray-700">
        <div class="p-6">
            <button aria-label="Menü schließen" class="absolute z-50 top-3.5 right-4 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" @click="closeNav">
                <icon icon="x-mark" classes="h-6 w-6"></icon>
            </button>
            <nav id="nav">
                <ul class="space-y-6 border-l border-slate-100 dark:border-slate-600">
                    <li>
                        <router-link :class="$route.hash !== '#hilfe' && $route.name === 'Home' && !archive.active(!!$route.query.date) ? 'active' : null" :to="{name:'Home'}" @click.native="closeNav" class="item">Spielen</router-link>
                    </li>
                    <li>
                        <router-link replace active-class="active" :to="{name:'Stats'}" @click.native="closeNav(false)" class="item">Statistiken</router-link>
                    </li>
                </ul>
                <archive-nav @callback="closeNav"></archive-nav>
                <extended-nav @callback="closeNav"></extended-nav>
                <span class="block mt-8 mb-4 lg:mb-3 font-semibold text-slate-900 dark:text-slate-200">Einstellungen</span>
                <ul class="space-y-4">
                    <li>
                        <div data-popover id="help-hard-mode-content" role="tooltip" class="absolute z-50 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                            <button @click="closeHelper" aria-label="Fenster schließen" class="absolute top-3 right-3 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <icon icon="x-mark" classes="w-5 h-5"></icon>
                            </button>
                            <div class="p-3 space-y-2">
                                <h3 class="font-semibold text-gray-900 dark:text-white">Wie funktioniert der schwierige Modus?</h3>
                                <p>Im schwierigen Modus {{ addressingUser('musst du','müssen Sie') }} Buchstaben wiederverwenden,
                                    die im vorherigen Versuch bereits anhand der Farben ({{ !contrast ? 'gelb oder grün' : 'blau oder orange'}}) verraten haben,
                                    dass sie im gesuchten Wort vorkommen.</p>
                                <p>Ist ein Buchstabe {{ !contrast ? 'gelb' : 'blau' }} markiert, <strong>muss der Buchstabe</strong> im nächsten Versuch <strong>genutzt werden</strong>,
                                    kann aber an einer beliebigen Stelle stehen</p>
                                <p>Ist ein Buchstabe {{ !contrast ? 'grün' : 'orange' }} markiert,
                                    muss dieser im nächsten Versuch <strong>genau an der selben Stelle</strong> verwendet werden.</p>
                            </div>
                            <div data-popper-arrow></div>
                        </div>
                        <label for="toggle-hard" class="inline-flex mr-5 items-start relative cursor-pointer" :class="disabledHardMode ? 'cursor-not-allowed' : null">
                            <input type="checkbox" value="" id="toggle-hard" :disabled="disabledHardMode" class="sr-only peer" v-model="hardMode">
                            <span class="w-11 flex-none h-6 bg-gray-200 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-500 contrast:peer-checked:bg-orange-400"></span>
                            <span class="ml-3">
                                <span class="text-sm h-6 flex items-center font-medium" :class="disabledHardMode ? 'text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-gray-300'">
                                    <span class="mr-2">Schwieriger Modus</span>
                                    <button title="Erklärung zum schwierigen Modus" id="help-hard-mode-trigger" class="text-gray-400 dark:text-gray-800 hover:text-brand-500 hover:dark:text-brand-300 hover:contrast:!text-orange-400">
                                        <icon icon="question-mark-circle" type="solid" classes="w-6 h-6"></icon>
                                    </button>
                                </span>
                                <span class="text-xs block font-normal text-gray-500 dark:text-gray-400 pr-8">
                                    <template v-if="!hardModeChangeAllowed">{{ addressingUser('Du musst','Sie müssen') }} ein Spiel starten, um den Modus zu ändern.</template>
                                    <template v-else-if="currentGameInProcess && !hardMode">Der Modus kann nur zu Beginn eines Spiels aktiviert werden.</template>
                                </span>
                            </span>
                        </label>
                    </li>
                    <li>
                        <label for="toggle-darkmode" @click="config.common['with_authentication'] && !auth && !store.getters.isDarkMode ? handleBlocked() : null" class="cursor-pointer inline-flex relative items-center mr-5">
                            <input type="checkbox" value="" id="toggle-darkmode" :disabled="config.common['with_authentication'] && !auth && !store.getters.isDarkMode" class="sr-only peer" v-model="darkMode">
                            <span class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-500 contrast:peer-checked:bg-orange-400"></span>
                            <span class="ml-3 text-sm font-medium" :class="config.common['with_authentication'] && !auth && !store.getters.isDarkMode ? 'text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-gray-300'">Dunkles Layout</span>
                            <LockedIcon v-if="config.common['with_authentication'] && !auth && !store.getters.isDarkMode" :svg-classes="['w-5','h-5','fill-current','ml-2']" icon-classes="h-5 w-5 ml-2 text-gray-400"></LockedIcon>
                        </label>
                    </li>
                    <li>
                        <label for="toggle-contrast" class="inline-flex relative items-center mr-5 cursor-pointer">
                            <input type="checkbox" value="" id="toggle-contrast" class="sr-only peer" v-model="contrast">
                            <span class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-500 contrast:peer-checked:bg-orange-400"></span>
                            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Hoher Kontrast</span>
                        </label>
                    </li>
                </ul>
                <div class="text-gray-500 dark:text-gray-400 border-t border-slate-100 dark:border-slate-600 pt-4 mt-6">
                    <Footer></Footer>
                </div>
                <related-publications></related-publications>
            </nav>
        </div>
    </div>
    <div v-bind:aria-hidden="!canvas ? 'true' : 'false'" v-bind:class="[!canvas ? 'bg-opacity-0 invisible' : (integration() === 'dns' ? 'bg-opacity-50 bg-gray-900 visible' : 'bg-white/30 backdrop-blur-sm')]" @click="closeNav" class="fixed z-40 inset-0 transition-all duration-300"></div>
</template>

<script>
import Footer from "./Footer"
import AuthIcon from "../vendor/publisher/components/AuthIcon"
import HelpIcon from "../vendor/publisher/components/HelpIcon.vue"
import ExtendedNav from "../vendor/publisher/components/ExtendedNav"
import RelatedPublications from "../vendor/publisher/components/RelatedPublications"
import Archive from "../vendor/publisher/pages/Archive"
import ArchiveNav from "../vendor/publisher/components/ArchiveNav"
import Icon from "../vendor/publisher/components/Icon"
import LockedIcon from "../vendor/publisher/components/LockedIcon.vue";
import { Popover } from 'flowbite'

export default {
    name: "Nav",
    components: {
        ArchiveNav,
        Archive,
        AuthIcon,
        ExtendedNav,
        Footer,
        HelpIcon,
        Icon,
        LockedIcon,
        RelatedPublications,
    },
    data() {
        return {
            canvas: false,
            transition: true,
            helper: null,
            config: this.$config,
            store: this.$store,
            archive: this.$archive
        }
    },
    watch: {
        '$route' (to) {
            this.closeNav(to.name !== 'Stats');
        }
    },
    computed: {
        hardMode: {
            get() {
                return this.$store.getters.isHardMode;
            },
            set(status) {
                this.switchSetup(status,"hardMode");
            }
        },
        darkMode: {
            get() {
                return this.$store.getters.isDarkMode;
            },
            set(status) {
                this.switchSetup(status,"darkMode","dark");
            }
        },
        contrast: {
            get() {
                return this.$store.getters.isContrast;
            },
            set(status) {
                this.switchSetup(status,"contrast","contrast");
            }
        },
        auth() {
            return this.$store.getters["auth/isAuth"];
        },
        hardModeChangeAllowed: function() {
            return this.$route["meta"]?.hardModeChangeAllowed;
        },
        currentGameInProcess: function() {
            return this.$store.getters.currentGameInProcess;
        },
        disabledHardMode: function() {
            return !this.hardModeChangeAllowed || (this.currentGameInProcess && !this.hardMode);
        },
    },
    mounted() {
        this.helper = new Popover(document.getElementById("help-hard-mode-content"), document.getElementById("help-hard-mode-trigger"), {
            triggerType: "click",
            placement: "bottom"
        });
    },
    methods: {
        /**
         *
         * @returns {*}
         */
        integration() {
            return this.$integration();
        },
        /**
         *
         * @param informal {string}
         * @param formal {string}
         * @returns {string}
         */
        addressingUser(informal,formal) {
            return this.$addressingUser(informal,formal);
        },
        openNav() {
            this.canvas = true;
        },
        /**
         *
         * @param transition
         */
        closeNav(transition = true) {
            window.dispatchEvent(new CustomEvent("feature:blocked",{
                detail: {
                    blocked: false
                }
            }));
            this.transition = transition;
            this.canvas = false;
            setTimeout(() => {
                this.transition = true;
            }, 300);
        },
        /**
         *
         * @param status
         * @param key
         * @param _class
         */
        switchSetup(status,key,_class = null) {
            this.$store.commit("setSetup",{key,_class,status});
            this.$store.dispatch("writeToLocalStorage", {
                key: "pref-" + key.toLowerCase(),
                val: status
            });
        },
        handleBlocked() {
            window.dispatchEvent(new CustomEvent("feature:blocked",{
                detail: {
                    blocked: true
                }
            }));
        },
        /**
         *
         * @param e
         */
        closeHelper(e) {
            this.helper.hide();
            e.stopPropagation();
        },
    },
}
</script>
