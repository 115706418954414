let config = {
    identifier: process.env.IDENTIFIER,
    name: process.env.NAME,
    asset_url: process.env.ASSET_URL,

    common: {
        domain: window.location.host,
        revision: process.env.REVISION,
    },

    setup: {
        game: {
            words: parseInt(process.env.MIX_GAME_MAX),
            rows: parseInt(process.env.MIX_GAME_ROWS),
            cols: parseInt(process.env.MIX_GAME_COLS),
            max_archive_days: parseInt(process.env.MIX_GAME_MAX_ARCHIVE_DAYS),
            // preload_days: parseInt(process.env.MIX_GAME_PRELOAD_DAYS),
            exclude_puzzles: process.env.MIX_GAME_EXCLUDE_PUZZLES?.split(",").map(x=>+x) || [],
            achievements: {
                0:   "Beginner",
                3:   "Hoffnungsschimmer",
                10:  "Straight Flush",
                20:  "Unter Beobachtung",
                40:  "Duden durchgelesen",
                70:  "Wörterbuch",
                100: "Durchgespielt!",
            }
        }
    },

    layout: {
        colors: {
            success: "bg-green-700 contrast:bg-orange-500",
            fail: "bg-stone-400",
            near: "bg-yellow-400"
        },
    },

    content: {
        addressing_users: "informal",
        messages: {
            notExists: "Das Wort ist nicht in der Wortliste.",
            warn: "Das hat leider nicht geklappt.",
            game: {
                success: {
                    0: [
                        "Wow, direkt beim ersten Versuch!",
                        "Das geht nicht mit rechten Dingen zu!",
                        "Wahnsinn! Geraten oder gefühlt?",
                        "Heute wurde wohl das persönliche Startwort gesucht"
                    ],
                    1: [
                        "Oha, da ist ein Genie am Werk!",
                        "Das schaffen nur sehr wenige!",
                        "Glückwunsch, nur zwei Versuche benötigt!"
                    ],
                    2: [
                        "Super, das ging fix!",
                        "Richtig gute Leistung!",
                        "Erfolg! Das Rätsel ist geknackt!",
                        "Wunderbar! Das war Spitze!"
                    ],
                    3: [
                        "Gut, zwei Versuche waren noch übrig.",
                        "Solider Durchschnitt.",
                        "Wort gefunden! Gut gemacht!"
                    ],
                    4: [
                        "Glückwunsch, Wort erraten!",
                        "Geschafft! Ein Versuch war noch übrig.",
                        "Gesuchtes Wort gefunden! Großartig!"
                    ],
                    5: [
                        "Sehr knapp, aber geschafft!",
                        "Puh, auf den letzten Drücker!",
                        "Hauptsache gelöst!",
                        "Unglaublich! Im letzten Versuch das Rätsel gelöst!",
                        "Knapp, aber erfolgreich! Letzter Versuch war ein Treffer!"
                    ]
                }
            }
        }
    },
}

/**
 *
 * @param preload
 * @param puzzles
 * @returns {number}
 */
function preloadPuzzles(preload = window.Publisher.setup?.game["max_archive_days"] ?? 7,puzzles = window.Publisher.setup?.game["words"] ?? 1) {
    return ((preload*2)+2)*puzzles;
}

export { config, preloadPuzzles }
