import axios from "axios"
import { get, set, del, _keys, getAll } from "@publisher/idb.js"
import { config, preloadPuzzles } from "../config"

export let game = {
    state: () => ({
        game: {
            nr: 0,
            row: 0,
            success: false,
            end: false,
            // score: [...Array(config.setup.game.rows)].map(x => []),
            score: [],
            hard: false,
            focus: {
                row: 0,
                col: 0
            },
            archive: false,
            date: null,
            solution: [],
        },
        games: {},
        puzzles: {}
    }),
    actions: {
        /**
         *
         * @param commit
         * @param getters
         * @param games
         */
        setGames: ({commit,getters},games) => {
            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            commit("resetRemainingGames",config.setup.game.words);

            const today = getters.today,
                maxArchiveDate = new Date(new Date(today).setHours(0,0,0,0));

            maxArchiveDate.setDate(maxArchiveDate.getDate()-config.setup.game.max_archive_days);

            Object.keys(games).forEach((date) => {

                const gameDate = new Date(new Date(date).setHours(0,0,0,0));

                if(gameDate >= maxArchiveDate) {
                    Object.keys(games[date]).forEach((solution) => {
                        if(games[date][solution].nr) {
                            commit("setGame", {
                                date: date,
                                solution: solution,
                                nr: games[date][solution].nr,
                                row: games[date][solution].row,
                                success: games[date][solution].success,
                                end: games[date][solution].end,
                                score: games[date][solution].score,
                                hard: games[date][solution].hard
                            })
                        }

                        /**
                         * Jedes Spiel, das heute bereits beendet wurde,
                         * zieht von Store.stats.remainingGames 1 ab
                         */
                        if (date === today && games[date][solution].end === true) {
                            commit("setRemainingGames", -1);
                        }
                    });
                }
            });

        },
        /**
         *
         * @param state
         * @param dispatch
         * @param getters
         * @returns {Promise<unknown>}
         */
        getGames: ({dispatch,getters}) => {
            return new Promise(async (resolve, reject) => {
                try {
                    await dispatch("readIndexedDB");
                } catch (error) {}

                if(typeof getters.getConfig === 'function') {
                    config = getters.getConfig;
                }

                // Keine Spiele für heute vorhanden oder nicht alle Spiele vorgeladen
                if(!getters.getAllPuzzlesByDate(getters.today) || getters.countAllPuzzles < preloadPuzzles()) {
                    if(getters.getAllPuzzlesByDate(getters.today)) { // Spiele für heute vorhanden
                        resolve(true);
                    }

                    axios.get('/api/get')
                        .then((response) => {
                            dispatch("setPuzzles",response.data.puzzles ?? {})
                                .then(() => {
                                    resolve(true);
                                })
                                .catch(function (error) {
                                    reject(error);
                                });
                        })
                        .catch(function(error) {
                            reject(error);
                        });
                }
                else {
                    resolve(true);
                }
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param nr
         * @returns {Promise<unknown>}
         * Todo: Is not used
         * @deprecated
         */
        getGame: ({commit,dispatch},nr) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let game = await get(parseInt(nr));
                    resolve({
                        nr: nr,
                        word: game.word,
                        date: game.date
                    });
                } catch (e) {
                    reject(null);
                }
            });
        },
        /**
         *
         * @param commit
         * @param state
         * @param puzzles
         * @returns {Promise<Awaited<unknown>[]>}
         */
        setPuzzles: ({commit,state},puzzles) => {
            let promises = [];

            if(Object.keys(puzzles).length > 0) { // Puzzles available?
                for(const [date,daily] of Object.entries(puzzles)) {
                    for (const puzzle of daily) {
                        promises.push(new Promise((resolve) => {
                            if(!state.puzzles[date] || !state.puzzles[date].find(e => e.nr === puzzle["nr"])) {
                                set(puzzle["nr"], {word: puzzle["word"], date: date}).finally(() => {
                                    commit("setPuzzle", {
                                        date: date,
                                        puzzle: puzzle
                                    });
                                    resolve();
                                });
                            }
                            else {
                                resolve("Puzzle already exists!");
                            }
                        }));
                    }
                }
            }

            return Promise.all(promises);
        },
        /**
         * Retrieve puzzles from indexedDB
         * and set state > puzzles
         * @param commit
         * @param getters
         * @returns {Promise<void>}
         */
        async readIndexedDB({commit,getters}) {
            let [keys, values] = await Promise.all([_keys(), getAll()]);
            const puzzles = {}, minPreloadDate = new Date();

            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            if(keys.length > 0) {
                minPreloadDate.setDate(minPreloadDate.getDate() - (config.setup?.game["max_archive_days"] ?? 0) - 1);
                minPreloadDate.setUTCHours(0,0,0,0);

                await keys.forEach((nr,index) => {
                    /**
                     * Puzzle werden gelöscht wenn:
                     * a) Das Spiel veraltet ist (today - GAME_MAX_ARCHIVE_DAYS - 2 days)
                     * b) Es in der Ausschlussliste steht, da es aus der DB entfernt wurde
                     */
                    if(new Date(values[index].date) < minPreloadDate) {
                        del(parseInt(nr)).then(ignore => {});
                        return;
                    }
                    else if(!puzzles[values[index].date]) { // Datum noch nicht in IndexedDB
                        puzzles[values[index].date] = [];
                    }
                    // Word already exists
                    else if(puzzles[values[index].date].find(item => item.word === values[index].word)) {
                        del(parseInt(nr)).then(ignore => {});
                        return;
                    }
                    puzzles[values[index].date].push({
                        nr: nr,
                        word: values[index].word,
                        date: values[index].date
                    });
                });

                commit("setPuzzles",puzzles);
            }
        }
    },
    mutations: {
        /**
         *
         * @param state
         * @param game
         */
        setCurrentGame: (state,game) => {
            state.game.nr = parseInt(game.nr);
            state.game.row = parseInt(game.row);
            state.game.success = Boolean(game.success);
            state.game.end = Boolean(game.end);
            state.game.score = game.score;
            state.game.hard = Boolean(game.hard);
            state.game.archive = Boolean(game.archive);
            state.game.date = game.date;
            state.game.solution = game.solution;
        },
        /**
         *
         * @param state
         * @param row
         * @param col
         */
        setCurrentFocus: (state,row,col = 0) => {
            state.game.focus.row = parseInt(row);
            state.game.focus.col = Number(col);
        },
        /**
         *
         * @param state
         * @param date
         * @param solution
         * @param nr
         * @param row
         * @param success
         * @param end
         * @param score
         * @param hard
         */
        setGame: (state,{
            date, solution, nr, row, success, end, score, hard
        }) => {
            state.games[date] = state.games[date] ?? {};
            state.games[date][solution] = {
                nr: parseInt(nr),
                row: parseInt(row),
                success: Boolean(success),
                end: Boolean(end),
                score: score,
                hard: Boolean(hard)
            };
        },
        /**
         *
         * @param state
         * @param date
         * @param solution
         * @param key
         * @param val
         */
        updateGame: (state,{
            date,solution,key,val
        }) => {
            state.games[date][solution][key] = val;
        },
        /**
         *
         * @param state
         * @param key
         * @param val
         */
        updateCurrentGame: (state,{key,val}) => {
            state.game[key] = val;
        },
        /**
         * Sorts games to achieve the same structure of database and localStorage
         * @param state
         */
        sortGames: (state) => {
            state.games = Object.keys(state.games)
                .sort()
                .reduce((accumulator,key) => {
                    accumulator[key] = state.games[key];
                    return accumulator;
                },{});
        },
        /**
         *
         * @param state
         */
        resetGames: (state) => {
            state.games = {};
        },
        /**
         *
         * @param state
         * @param date
         * @param puzzle
         */
        setPuzzle: (state,{date,puzzle}) => {
            if(!state.puzzles[date]) {
                state.puzzles[date] = [];
            }
            state.puzzles[date].push({
                nr: puzzle.nr,
                word: puzzle.word,
                date: date
            });
            state.puzzles[date].sort((a,b) => {
                return a.nr - b.nr;
            });
        },
        /**
         *
         * @param state
         * @param puzzles
         */
        setPuzzles: (state,puzzles) => {
            state.puzzles = puzzles;
        }
    },
    getters: {
        /**
         * Get the current game of the user
         * @param state
         * @returns {{}|null}
         */
        getCurrentGame: (state) => {
            if(state.game.nr > 0) {
                return state.game;
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        currentGameInProcess: (state) => {
            return state.game.nr > 0 && state.game.focus.row > 0 && state.game.end === false;
        },
        /**
         * Get a game of the user by date and solution
         * @param state
         * @returns {(function(*, *): (*|null))|*}
         */
        getGameById: (state) => (date,solution) => {
            if(state.games[date] !== undefined && state.games[date][solution] !== undefined) {
                return state.games[date][solution];
            }

            return null;
        },
        /**
         * Get all games of the user
         * @param state
         * @returns {{}|null}
         */
        getAllGames: (state) => {
            if(Object.keys(state.games).length > 0) {
                return state.games;
            }

            return null;
        },
        /**
         * Get all games of the user by date
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getGamesByDate: (state) => (date) => {
            if(state.games[date] !== undefined) {
                return state.games[date];
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (number|number))|*}
         * TODO: Maybe check game.end
         */
        getCountGamesByDate: state => (date) => {
            if(state.games[date] !== undefined) {
                return Object.keys(state.games[date]).length;
                // return Object.entries(state.games[date]).filter(game => game.end).length;
            }

            return 0;
        },
        /**
         *
         * @param state
         * @returns {function(*, *, *=): number}
         */
        getCountGamesBetweenDate: state => (start,end,games = null) => {
            let count = 0;

            for(const [date] of Object.entries(games ?? state.games)) {
                const currentDate = new Date(date);
                if(currentDate <= start && currentDate >= end) {
                    count++;
                }
            }

            return count;
        },
        /**
         * Get the last played game of the user
         * @param state
         * @param getters
         * @returns {function(*=, *=): *}
         */
        getLastGameByDate: (state,getters) => (end = false,date = getters.today) => {

            let sorted = [], i = 0, games = state.games[date] ?? [];

            for(let solution in games) {
                sorted[i] = games[solution];
                sorted[i].solution = solution;
                i++;
            }
            sorted.sort(function(a,b) {
                return a.nr - b.nr;
            });

            games = [];

            sorted.forEach((game) => {
                if(game.end === end) {
                    games.push(game);
                }
            });

            return games.length > 0 ? games.slice(-1)[0] : {};

        },
        /**
         *
         * @param state
         * @returns {*}
         */
        getAllPuzzles: state => state.puzzles,
        /**
         *
         * @param state
         * @returns {number}
         */
        countAllDays: state => {
            const toArr = Object.getOwnPropertyNames(state.puzzles);
            return toArr.length;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {number}
         */
        countAllPuzzles: (state,getters) => {
            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            return getters.countAllDays * config.setup.game.words;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {(function(*=): (*|undefined))|*}
         */
        getAllPuzzlesByDate: (state,getters) => (date = getters.today) => {
            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            if(Object.keys(state.puzzles).length > 0 && state.puzzles[date] && state.puzzles[date].length === config.setup.game.words) {
                return state.puzzles[date];
            }
            else if(config["debug"]) {
                console.log("No puzzles were passed");
                console.log("state.puzzle >");
                console.log(state.puzzles);
                console.log("date >");
                console.log(date);
            }

            return null;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {any}
         */
        getPuzzleById: (state,getters) => (index,date = getters.today) => {
            if(Object.keys(state.puzzles).length > 0 && state.puzzles[date] !== undefined && state.puzzles[date][index] !== undefined) {
                return state.puzzles[date][index];
            }

            return null;
        },
    }
}
