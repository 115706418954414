<template>
    <!-- RankUp -->
    <div v-if="action('rankUp','overlay')" class="p-3 relative border-b border-gray-200 bg-gray-100 dark:border-gray-600 dark:bg-gray-700">
        <div v-if="auth" :class="!rank.next ? 'bg-fuchsia-100 dark:bg-fuchsia-200 text-fuchsia-600 border-fuchsia-400 contrast:!bg-fuchsia-100 contrast:!text-fuchsia-600' : 'bg-green-100 dark:bg-green-200 border-green-400 contrast:!bg-orange-100 text-green-900 contrast:!text-orange-900'" class="mb-2 border items-center text-sm inline-flex p-1.5 rounded">
            <icon v-if="rank.next" icon="rocket-launch" classes="mr-1 h-5 w-5 text-green-700 shrink-0"></icon>
            <icon v-else icon="sparkles" classes="mr-1 h-5 w-5 text-fuchsia-600 shrink-0"></icon>
            Aufstieg: <span class="font-semibold ml-1">{{ rank.current }}</span>
        </div>
        <icon v-else icon="check-badge" classes="absolute top-3 left-3 h-5 w-5 text-green-700 contrast:!text-orange-500"></icon>
        <p :class="!auth ? 'pl-6' : null" class="text-gray-900 font-normal text-sm dark:text-gray-300">
            Glückwunsch! {{ $addressingUser('Du hast','Sie haben') }} <strong>{{ $store.getters.getStat("streak") }}</strong> Wörter hintereinander erraten.
            <span v-if="$store.getters.isAuth && rank.next">Erziele{{ $addressingUser('','n Sie') }} eine Siegesstrecke von {{ rank.next }} für die nächste Stufe.</span>
            <span v-else-if="!rank.next">Damit {{ $addressingUser('hast du','haben Sie') }} die höchste Stufe erreicht!</span>
            <a :href="$config.sso.login_url" v-if="!auth && $config.sso.custom" class="flex-none link-intern" @click="$handleLogin">Logge{{ $addressingUser(' dich','n Sie sich') }} ein und schaue{{ $addressingUser(' dir deinen','n Sie sich Ihren') }} aktuellen Rang an</a>
            <router-link v-else-if="!auth" :to="{name:'Login'}" class="link-intern">Logge{{ $addressingUser(' dich','n Sie sich') }} ein und schaue{{ $addressingUser(' dir deinen','n Sie sich Ihren') }} aktuellen Rang an.</router-link>
        </p>
    </div>
    <!-- /RankUp -->
    <!-- ActionButtons -->
    <div :class="[!game.end ? 'rounded-b rounded-lg border-gray-200' : null]" class="flex items-center justify-center py-3 px-5 sm:p-5 space-x-3">
        <button v-if="action('next')" @click="newGame($event.target)" type="button" class="btn-primary whitespace-nowrap mr-2">
            Neues Spiel
            <span class="inline-flex justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-brand-500 bg-brand-100 rounded-full contrast:!bg-orange-100 contrast:!text-orange-500" v-if="$store.getters.getRemainingGames > 0">{{ $store.getters.getRemainingGames }}</span>
        </button>
        <button v-if="action('continue','overlay')" @click="closeOverlay" type="button" class="btn btn-default whitespace-nowrap">
            Spiel fortsetzen
        </button>
        <router-link v-if="action('archive','overlay')" :to="{name:'Archive'}" tag="button" class="btn btn-default whitespace-nowrap inline-flex items-center justify-self-end">
            Zum Archiv
            <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
        </router-link>
        <span class="text-gray-900 text-sm dark:text-gray-300" v-if="action('remaining')">{{ $addressingUser('Du kannst','Sie können') }} heute noch {{ $store.getters.getRemainingGames === 1 ? "ein" : $store.getters.getRemainingGames }} {{ parseInt($store.getters.getRemainingGames) === 1 ? 'Wort' : 'Wörter' }} erraten.</span>
        <vue-countdown v-if="action('countdown')" @end="onCountdownEnd" :time="tilNextDay()" v-slot="{ hours, minutes, seconds }">
            <time v-if="hours > 0 || minutes > 0 || seconds > 0" class="block text-gray-900 text-lg dark:text-gray-300">{{ config.setup.game.words === 1 ? "Ein" : config.setup.game.words }} neue{{config.setup.game.words === 1 ? "s" : ""}} {{ config.setup.game.words !== 1 ? "Wörter" : "Wort" }} in <strong>{{ hours }}h {{ minutes }}m und {{ seconds }}s</strong>.</time>
            <Spinner v-else classes="-mt-1 w-8 h-8 contrast:fill-orange-500"></Spinner>
        </vue-countdown>
    </div>
    <!-- /ActionButtons -->
    <!-- Promote archive & register -->
    <action-promote-archive v-if="action('promoteArchive','overlay')" class="py-3 flex border-t border-gray-200 bg-gray-100 px-5 sm:p-5 space-x-2 dark:bg-gray-700 dark:border-gray-600"></action-promote-archive>
    <!-- /Promote archive & register -->
    <!-- Rotate publications -->
    <action-rotate-publications v-else-if="action('promotePublication','overlay')" class="py-3 flex border-t border-gray-200 bg-gray-100 px-5 sm:p-5 space-x-2 dark:bg-gray-700 dark:border-gray-600"></action-rotate-publications>
    <!-- /Rotate publications -->
    <!-- Share -->
    <div v-if="action('share')" class="share flex justify-around items-center py-3 px-5 sm:p-5 space-x-2 border-t border-gray-200 dark:border-gray-600">
        <span class="inline-block text-gray-900 text-sm dark:text-gray-300">Letztes Spiel (#{{ share.nr }}): {{ getReadableRow(share.row,share.success) }}/{{ Object.keys(share.score).length }}{{ share.hard ? "*" : "" }}</span>
        <button v-on:click="shareResult(share.nr,share.row,share.success,share.score,share.hard,share.solution)" type="button" class="btn btn-primary inline-flex shrink-0 whitespace-nowrap">
            <icon v-if="$store.getters.canShare" icon="share" classes="mr-2 w-5 h-5"></icon>
            <icon v-else icon="document-duplicate" classes="mr-2 w-5 h-5"></icon>
            {{share.success === true ? 'Erfolg' : 'Spiel'}} teilen
        </button>
    </div>
    <!-- /Share -->
</template>

<script>
import Share from "../mixins/share.mix"
import VueCountdown from "@chenfengyuan/vue-countdown"
import Icon from "../vendor/publisher/components/Icon"
import ActionPromoteArchive from "../vendor/publisher/components/ActionPromoteArchive.vue"
import Spinner from "../vendor/publisher/components/Spinner.vue"
import ActionRotatePublications from "../vendor/publisher/components/ActionRotatePublications.vue";

export default {
    name: "Actions",
    props: ["game","overlay"],
    emits: ["new","close"],
    mixins: [
        Share
    ],
    components: {
        ActionRotatePublications,
        Spinner,
        ActionPromoteArchive,
        Icon,
        VueCountdown
    },
    data: function() {
        return {
            share: {
                nr: 0,
                row: 0,
                success: false,
                end: false,
                score: [...Array(this.$config.setup.game.rows)].fill([]),
                hard: false,
                archive: false,
                date: null,
                solution: []
            },
            rank: {
                current: null,
                next: null,
                playedThrough: false
            },
            config: this.$config
        }
    },
    computed: {
        /**
         *
         * @returns {*}
         */
        auth() {
            return this.$store.getters['auth/isAuth'];
        },
    },
    mounted() {
        this.setShare();
    },
    methods: {
        newGame() {
            this.$emit("new");
        },
        /**
         *
         * @param elm
         */
        closeOverlay(elm) {
            this.$emit("close",elm);
        },
        setShare() { // Todo: Why?
            if(this.game.archive === true || this.game.end === true) {
                this.share = this.game;
            }
            else {
                this.share = this.$store.getters.getLastGameByDate(true);
            }
        },
        /**
         *
         * @param action
         * @param display
         * @returns {false|string|((value: any) => void)|((message: any, ...args: any[]) => void)|Event|boolean|*|boolean|boolean|string}
         */
        action(action = null,display = "always"/* ['always','overlay','game']*/) {
            if((display === "overlay" && !this.overlay) || (display === "game" && this.overlay)) {
                return false;
            }

            const games = this.$store.getters.getStat("games");
            const streak = this.$store.getters.getStat("streak");
            const random = Math.floor(Math.random() * 2);

            const actions = {
                "next": () => {
                    return this.game.end && this.$store.getters.getRemainingGames > 0 && !this.game.archive;
                },
                "remaining": () => {
                    return this.$store.getters.getRemainingGames > 0 && !this.game.archive;
                },
                "continue": () => {
                    return !this.game.end && (this.$store.getters.getRemainingGames > 0 || this.game.archive)
                },
                "countdown": () => {
                    return !this.game.archive && this.$store.getters.getRemainingGames === 0;
                },
                "rankUp": () => {
                    if(this.config.common.with_authentication && streak > 0 && streak in this.$config.setup.game.achievements) {
                        this.rank.current = this.$config.setup.game.achievements[streak];
                        this.rank.next = this.nextRank(streak);
                        return true;
                    }

                    return false;
                },
                "tryHardMode": () => {
                    return games > 2 && this.game.end && this.game.success && !this.$store.getters.isHardMode && this.game.row < 3 && this.game.row > 0 && (this.$store.getters.getRemainingGames > 0 || this.game.archive);
                },
                "promoteArchive": () => {
                    if(this.config.common.with_authentication && streak > 0 && streak in this.$config.setup.game.achievements) {
                        return false;
                    }

                    // Todo: and games are left
                    return random === 0 && !this.game.archive && this.$archive.enabled() && this.game.end && this.$store.getters.getRemainingGames === 0;
                },
                "promotePublication": () => {
                    if(this.config.common.with_authentication && streak > 0 && streak in this.$config.setup.game.achievements) {
                        return false;
                    }

                    return this.hasConnection && this.$config?.publications && this.$config?.publications.length > 0 && this.game.end && this.$store.getters.getRemainingGames === 0;
                },
                "share": () => {
                    return this.game.end;
                },
                "archive": () => {
                    return this.game.archive;
                },
            };

            return actions[action]();
        },
        /**
         *
         * @param current
         * @returns {boolean|string}
         */
        nextRank(current) {
            const keys = Object.keys(this.$config.setup.game.achievements), i = keys.indexOf(current.toString());

            if(i !== -1) {
                return keys[i + 1];
            }

            return false;
        },
    }
}
</script>
