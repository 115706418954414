export default {
    methods: {
        /**
         *
         * @param nr
         * @param row
         * @param success
         * @param score
         * @param solution
         * @param hard
         * @returns {string}
         */
        buildShareText(nr,row,success,score,hard,solution) {
            return "Wort #"
                + nr
                + " in "
                + this.getReadableRow(row,success)
                + " von "
                + Object.keys(score).length
                + " Versuchen"
                + (hard ? "*" : "") + "\n"
                + this.buildEmojiSquares(row,solution,score) + "\n"
                + this.$config.common.domain + " - " + this.$config.name
        },
        /**
         * green medium square: &#129001;
         * yellow medium square: &#129000;
         * grey medium square: &#11036;
         */
        buildEmojiSquares(row,solution,score) {
            let emoji = "";

            for(let i = 0;i<=row;i++) {
                let result = this.getResult(i,this.decrypt(solution),score);

                result.forEach((obj) => {
                    switch (obj.status) {
                        case "success":
                            emoji += this.$store.getters.isContrast ? "🟧" : "🟩";
                            break;

                        case "near":
                            emoji += this.$store.getters.isContrast ? "🟦" : "🟨";
                            break;

                        default:
                            emoji += this.$store.getters.isContrast ? "⬛" : "⬜";
                            break;
                    }
                });
                emoji += "\n";
            }

            return emoji;
        },

        /**
         *
         * @param nr
         * @param row
         * @param success
         * @param score
         * @param solution
         * @param hard
         */
        shareResult(nr,row,success,score,hard,solution) {
            const data = {
                title: this.$config.name,
                text: this.buildShareText(nr,row,success,score,hard,solution)
            };

            if(this.$store.getters.canShare) {
                navigator.share(data)
                    .then(() => {
                        // Success share
                        this.event("ShareResult",{
                            game_nr: nr,
                            game_success_row: this.getReadableRow(row,success)
                        });
                    })
                    .catch(error => {
                        // console.log(error);
                        this.$store.dispatch("showToast",{
                            msg: "Ergebnis kopiert!",
                            type: "success"
                        });
                    });

                this.copyToClipboard(data.text,false);
            }
            else {
                this.copyToClipboard(data.text);
            }
        },
        /**
         *
         * @param text
         * @param toast
         */
        copyToClipboard(text,toast = true) {
            navigator.clipboard.writeText(text).then(() => {
                if(toast === true) {
                    this.$store.dispatch("showToast",{
                        msg: "Ergebnis kopiert!",
                        type: "success"
                    });
                    this.event("CopyResult");
                }
            }, function(err) {
                // console.error('Async: Could not copy text: ', err);
            });
        },
        /**
         *
         * @param row
         * @param success
         * @returns {*|string}
         */
        getReadableRow(row,success) {
            return success === true ? (row+1) : "X";
        }
    }
}
